import 'react-native-gesture-handler'
import { LinkingOptions, NavigationContainer } from '@react-navigation/native'
import { createStackNavigator, StackScreenProps } from '@react-navigation/stack'
import axios from 'axios'
import * as Linking from 'expo-linking'
import React from 'react'
import { Text, View, TextInput } from 'react-native'
import { BottomSheet, Button, ListItem, Overlay } from 'react-native-elements'

type HomeParam = {
  player: string
  rule: string
}

type RootParamList = {
  Home: HomeParam
}

const RootStack = createStackNavigator<RootParamList>()

export default function App() {
  const prefix = Linking.createURL('/')

  const linking: LinkingOptions<RootParamList> = {
    prefixes: [prefix],
    config: {
      screens: {
        Home: '',
      },
    },
  }

  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <RootStack.Navigator screenOptions={{ headerShown: false }}>
        <RootStack.Screen
          name='Home'
          component={HomeScreen}
          options={{ title: '아발론' }}
          initialParams={{ player: '', rule: '' }}
        />
      </RootStack.Navigator>
    </NavigationContainer>
  )
}

function HomeScreen({ route, navigation }: StackScreenProps<RootParamList, 'Home'>) {
  const [playerText, setPlayerText] = React.useState(route.params.player)
  const [ruleText, setRuleText] = React.useState(route.params.rule)
  const [loading, setLoading] = React.useState(false)
  const [bottomSheetVisible, setBottomSheetVisible] = React.useState(false)
  const [overlayVisible, setOverlayVisible] = React.useState(false)
  const [overlayText, setOverlayText] = React.useState('')

  function toggleOverlay() {
    setOverlayVisible(!overlayVisible)
  }

  const bottomSheetList = [
    {
      title: '5인: (선)멀린+신하2, (악)암살자+하수인',
      onPress: () => {
        setRuleText(rule5)
        setBottomSheetVisible(false)
      },
    },
    {
      title: '6인: (선)멀린+퍼시벌+신하2, (악)암살자+모르가나',
      onPress: () => {
        setRuleText(rule6)
        setBottomSheetVisible(false)
      },
    },
    {
      title: '7인: (선)멀린+퍼시벌+신하2, (악)암살자+모르가나+오베론',
      onPress: () => {
        setRuleText(rule7)
        setBottomSheetVisible(false)
      },
    },
    {
      title: '8인: (선)멀린+퍼시벌+신하3, (악)암살자+모르가나+오베론',
      onPress: () => {
        setRuleText(rule8)
        setBottomSheetVisible(false)
      },
    },
    {
      title: '전부 지우기',
      onPress: () => {
        setPlayerText('')
        setRuleText('')
        setBottomSheetVisible(false)
      },
      containerStyle: { backgroundColor: 'pink' },
    },
    {
      title: '닫기',
      onPress: () => setBottomSheetVisible(false),
      containerStyle: { backgroundColor: 'red' },
      titleStyle: { color: 'white' },
    },
  ]

  async function sendEmail() {
    setLoading(true)

    try {
      const data: AvalonInputType = {
        player: playerText,
        rule: ruleText,
        token: 'savano',
      }
      const response = await apiSendEmail(data)
      const text = JSON.stringify(response, null, 2)
      setOverlayText(text)
    } catch (error) {
      console.log(error)
      const text = JSON.stringify(error, null, 2)
      setOverlayText(text)
    }

    setOverlayVisible(true)
    setLoading(false)
  }

  function showPresets() {
    setBottomSheetVisible(true)
  }

  function showTest() {
    setPlayerText(testPlayer)
    setRuleText(testRule)
  }

  function openGoogleAllowAccess() {
    Linking.openURL('https://g.co/allowaccess')
  }

  function changeUrl() {
    const init: HomeParam = {
      player: playerText,
      rule: ruleText,
    }
    const params = new URLSearchParams(init)
    const url = '/?' + params.toString()
    history.pushState(null, '', url)
  }

  React.useEffect(() => {
    changeUrl()
  }, [playerText, ruleText])

  return (
    <View style={{ flex: 1, backgroundColor: 'white' }}>
      {/** 헤더 */}
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 10,
          backgroundColor: '#2F4F4F',
          flexDirection: 'row',
        }}>
        <Text style={{ color: 'white', fontWeight: 'bold', fontSize: 20 }}>
          레지스탕스 아발론 스타터
        </Text>
        <Button onPress={openGoogleAllowAccess} title='Gmail 로그인 허용하기' type='clear' />
      </View>
      {/** 본문: 텍스트 입력 */}
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, padding: 10 }}>
          <Text style={{ fontSize: 17, marginBottom: 10 }}>플레이어 명단</Text>
          <TextInput
            placeholder={player5}
            style={{
              flex: 1,
              fontSize: 15,
              borderWidth: 1,
              borderColor: 'lightgrey',
              borderRadius: 2,
            }}
            value={playerText}
            onChangeText={setPlayerText}
            multiline
          />
        </View>
        <View style={{ flex: 1.5, padding: 10 }}>
          <Text style={{ fontSize: 17, marginBottom: 10 }}>캐릭터 별 메시지</Text>
          <TextInput
            placeholder={rule5}
            style={{
              flex: 1,
              fontSize: 15,
              borderWidth: 1,
              borderColor: 'lightgrey',
              borderRadius: 2,
            }}
            value={ruleText}
            onChangeText={setRuleText}
            multiline
          />
        </View>
      </View>
      {/** 하단: 버튼 */}
      <View style={{ padding: 10, flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flexDirection: 'row' }}>
          <Button onPress={showPresets} title='사전설정' type='outline' />
          <View style={{ width: 10 }} />
          <Button onPress={showTest} title='테스트' type='clear' />
        </View>
        <Button onPress={sendEmail} title='이메일 발송' loading={loading} />
      </View>
      {/** 하단: 팝업 시트 */}
      <BottomSheet
        isVisible={bottomSheetVisible}
        containerStyle={{ backgroundColor: 'rgba(0.5, 0.25, 0, 0.2)' }}>
        {bottomSheetList.map((value, index) => (
          <ListItem key={index} containerStyle={value.containerStyle} onPress={value.onPress}>
            <ListItem.Content>
              <ListItem.Title style={value.titleStyle}>{value.title}</ListItem.Title>
            </ListItem.Content>
          </ListItem>
        ))}
      </BottomSheet>
      {/** 하단: 결과 오버레이 */}
      <Overlay isVisible={overlayVisible} onBackdropPress={toggleOverlay}>
        <Text style={{ fontFamily: 'monospace', fontSize: 15 }}>{overlayText}</Text>
      </Overlay>
    </View>
  )
}

interface AvalonInputType {
  player: string
  rule: string
  token: string
}

interface AvalonOutputType {
  result: string
}

async function apiSendEmail(avalonInput: AvalonInputType) {
  try {
    const { data } = await axios.post<AvalonOutputType>(
      'https://avalon-game-api.herokuapp.com/avalon',
      avalonInput
    )
    console.log(data)
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

const player5 = `
철수: kang@mailinator.com 
영희: min@mailinator.com 
택후: kim@mailinator.com 
유미: gong@mailinator.com 
민수: sung@mailinator.com
`.trim()

const testPlayer = `
A: a_avalon@mailinator.com
B: b_avalon@mailinator.com
C: c_avalon@mailinator.com
D: d_avalon@mailinator.com
E: e_avalon@mailinator.com
F: f_avalon@mailinator.com
G: g_avalon@mailinator.com
H: h_avalon@mailinator.com
`.trim()

const testRule = `
멀린: 당신은 멀린입니다. {암살자, 모르가나, 오베론}은 악의 세력입니다.
퍼시벌: 당신은 퍼시발입니다. {멀린, 모르가나} 중 한 명은 멀린이고, 한 명은 모르가나입니다.
신하1: 당신은 아서왕의 충실한 부하입니다.
신하2: 당신은 아서왕의 충실한 부하입니다.
신하3: 당신은 아서왕의 충실한 부하입니다.
암살자: 당신은 암살자입니다. {모르가나}는 악의 세력입니다.
모르가나: 당신은 모르가나입니다. {암살자}는 악의 세력입니다.
오베론: 당신은 오베론입니다. 다른 악의 세력을 알 수 없습니다.
`.trim()

// https://namu.wiki/w/레지스탕스%20아발론#s-3.2
const rule5 = `
멀린: 당신은 멀린입니다. {암살자, 하수인}은 악의 세력입니다.
신하1: 당신은 아서왕의 충실한 부하입니다.
신하2: 당신은 아서왕의 충실한 부하입니다.
암살자: 당신은 암살자입니다. {하수인}은 악의 세력입니다.
하수인: 당신은 모드레드의 하수인입니다. {암살자}는 악의 세력입니다.
`.trim()
const rule6 = `
멀린: 당신은 멀린입니다. {암살자, 모르가나}는 악의 세력입니다.
퍼시벌: 당신은 퍼시발입니다. {멀린, 모르가나} 중 한 명은 멀린이고, 한 명은 모르가나입니다.
신하1: 당신은 아서왕의 충실한 부하입니다.
신하2: 당신은 아서왕의 충실한 부하입니다.
암살자: 당신은 암살자입니다. {모르가나}는 악의 세력입니다.
모르가나: 당신은 모르가나입니다. {암살자}는 악의 세력입니다.
`.trim()
const rule7 = `
멀린: 당신은 멀린입니다. {암살자, 모르가나, 오베론}은 악의 세력입니다.
퍼시벌: 당신은 퍼시발입니다. {멀린, 모르가나} 중 한 명은 멀린이고, 한 명은 모르가나입니다.
신하1: 당신은 아서왕의 충실한 부하입니다.
신하2: 당신은 아서왕의 충실한 부하입니다.
암살자: 당신은 암살자입니다. {모르가나}는 악의 세력입니다.
모르가나: 당신은 모르가나입니다. {암살자}는 악의 세력입니다.
오베론: 당신은 오베론입니다. 다른 악의 세력을 알 수 없습니다.
`.trim()
const rule8 = `
멀린: 당신은 멀린입니다. {암살자, 모르가나, 오베론}은 악의 세력입니다.
퍼시벌: 당신은 퍼시발입니다. {멀린, 모르가나} 중 한 명은 멀린이고, 한 명은 모르가나입니다.
신하1: 당신은 아서왕의 충실한 부하입니다.
신하2: 당신은 아서왕의 충실한 부하입니다.
신하3: 당신은 아서왕의 충실한 부하입니다.
암살자: 당신은 암살자입니다. {모르가나}는 악의 세력입니다.
모르가나: 당신은 모르가나입니다. {암살자}는 악의 세력입니다.
오베론: 당신은 오베론입니다. 다른 악의 세력을 알 수 없습니다.
`.trim()
